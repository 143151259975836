* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 1em 2em;
  font-family: 'Open Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


